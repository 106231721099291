import React, { useEffect, useState } from 'react';

// CSS - Images - Icons
import "./Footer.css";
import brasao from "../../assets/img/Icon COGES.png";

// Componentes 
import mapa from "../../assets/backgroud/mapa.png"
import Menu from "./components/menus";
import Figureicon from "../pages/componentes/figureIcon";

// Hooks
import useComunicationAPI from "../../hooks/useComunicationAPI"

function Footer() {
  const [menu, setMenu] = useState([]);

  const { fetchMenu } = useComunicationAPI({});

  useEffect(() => {
    fetchMenu({ setMenu, name: "Menu do Rodapé" });
  }, []);

  return (
    <footer>
      <div className="mapa">
        <div className="container">
          <Figureicon
            alt="sessão de menus e sevisos da pagina"
          />
          <Menu menuItems={menu} />
        </div>
      </div>
      <div className="info">
        <div className="maps">
          <a href="https://www.google.com/maps/place/Madeira+River+Palace/@-8.750811,-63.914031,16z/data=!4m14!1m7!3m6!1s0x92325d2aa646406b:0xf2f9bfd5340a53fd!2sMadeira+River+Palace!8m2!3d-8.7495987!4d-63.9109519!16s%2Fg%2F11gbf7bbp7!3m5!1s0x92325d2aa646406b:0xf2f9bfd5340a53fd!8m2!3d-8.7495987!4d-63.9109519!16s%2Fg%2F11gbf7bbp7?hl=en&entry=ttu">
            <img src={mapa} alt="Localização da Contabilidade Geral do Estado deRondônia." />
          </a>
        </div>
        <div className="container">
          <div className="content">
            <div className="logos">
              <img src={brasao} alt="Brasão do Estado de Rondônia" />
            </div>
            <div className="content">
              <div>
                <div className="title"><i className="icon endereco"></i><p>Endereço</p></div>
                <div className="description"><p>Av. Farquar, 2986 - Palácio Rio Madeira - Edifício Rio Jamari, Curvo III, Térreo - Bairro Pedrinhas - CEP 76.801-470 - Porto Velho, RO</p></div>
                <div className="title"><i className="icon contato"></i><p>Telefone</p></div>
                <div className="description"><p>(69) 3211-6100</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className={`container`}>
        </div>
      </div>
    </footer>
  )
}

export default Footer