export function truncateText(text, wordLimit) {
    if (typeof text !== 'string' || typeof wordLimit !== 'number') {
        throw new Error('Os parâmetros devem ser uma string e um número.');
    }

    // Remove todas as tags HTML do texto
    const textWithoutHTML = text.replace(/<\/?[^>]+(>|$)/g, '');

    const words = textWithoutHTML.split(/\s+/);

    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + "...";
    }

    return textWithoutHTML;
}

export function formatarTamanho(tamanhoEmBytes) {
    const unidades = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
    let indiceUnidade = 0;

    // Converte para a próxima unidade quando tamanho >= 1024
    while (tamanhoEmBytes >= 1024 && indiceUnidade < unidades.length - 1) {
        tamanhoEmBytes /= 1024; // Converte para a próxima unidade
        indiceUnidade++; // Avança para a próxima unidade
    }

    // Formata para mostrar duas casas decimais
    const tamanhoFormatado = tamanhoEmBytes.toFixed(2);

    return `${tamanhoFormatado} ${unidades[indiceUnidade]}`;
}

export function extractPath(url) {
    try {
        const parsedUrl = new URL(url);
        const hostname = "alemdosnumeros.contabilidade.ro.gov.br";
        
        // Combina pathname, search (query strings) e hash (fragmento)
        if (parsedUrl.hostname === hostname) {
            return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
        } else {
            return `${parsedUrl}`;
        }
    } catch (error) {
        console.error("URL inválido:", error);
        return null;  // Retorna null se o URL for inválido
    }
}