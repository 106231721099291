import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Componentes
import Loading from "../../../components/pages/struture/Loading.js";
import NavPage from "../../../components/pages/componentes/nav.js";
import CategoriesBar from "../../../components/pages/componentes/CategoriesBar.js";

// Hooks
import useComunicationAPI from '../../../hooks/useComunicationAPI.js';

// Functions
import { formatarTamanho } from "../../../utilities/tratament_functions.js";

// CSS
import './TableOfPosts.css';

export default function TableOfPosts() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');
  const typePost = queryParams.get('type');
  const MIME_TYPE_TO_ICON = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'icon xlsx',
    'application/vnd.ms-excel': 'icon xls',
    'application/pdf': 'icon pdf'
  };

  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [info, setInfo] = useState({});

  const { groupPosts } = useComunicationAPI({ setPosts, setInfo, setIsLoading });

  useEffect(() => {
    window.scroll(0, 0);
    groupPosts({ category, typePost });
  }, [category, typePost]);

  let getIconClassForMimeType = (mimeType) => {
    // Se o tipo MIME existir no mapeamento, retorne a classe correspondente
    // Caso contrário, retorne uma classe de ícone padrão (por exemplo, 'icon-default')
    return MIME_TYPE_TO_ICON[mimeType] || 'icon default';
  }

  return (
    <div className="workspace">
      <NavPage info={info} />
      <div className="container posts ">
        <div className="post col-12 col-sm-8">
          {posts.length === 0 ? (<Loading />)
            : (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th className='title-column'>Nome</th>
                    <th>Tamanho</th>
                    <th>Baixar</th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((post) =>
                    <tr key={post.postId}>
                      <td><i className={`${getIconClassForMimeType(post.mime_type)}`}></i></td>
                      <td className='post-table'>{post.title}</td>
                      <td className='post-table'>{formatarTamanho(post.content)}</td>
                      <td>
                        <a href={post.link} target="_blank" rel="noopener noreferrer">Ver</a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
        </div>

        <div className="sidebar col-3">
          <CategoriesBar type={typePost} />
        </div>
      </div>
    </div>
  )
}